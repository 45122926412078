import request from '../static/request'

//登录
export function login(data){
    return request({
        url:'/front/login/login',
        method:'POST',
        data
    })
}
//忘记密码
export function forgotPassword(data){
    return request({
        url:'/front/login/forgotPassword',
        method:'POST',
        data
    })
}
// 首页统计
export function statistics(data){
    return request({
        url:'/front/index/statistics',
        method:'GET',
       params: data
    })
}
// 首页轮播
export function banner(data){
    return request({
        url:'/front/index/banner',
        method:'POST',
       data
    })
}
// 首页排行榜
export function leaderboard(data){
    return request({
        url:'/front/index/leaderboard',
        method:'GET',
       params: data
    })
}
// 联系我们
export function addMsg(data){
    return request({
        url:'/front/index/addMsg',
        method:'POST',
       data
    })
}
//文章详情
export function getInfo(data){
    return request({
        url:'/front/menu/getInfo',
        method:'GET',
       params: data
    })
}
//文章列表
export function listPain(data){
    return request({
        url:'/front/menu/listPain',
        method:'POST',
        data
    })
}

//获取验证码
export function getCode(data){
    return request({
        url:'/front/login/sendMessage',
        method:'GET',
        params:data
    })
}

//立即注册
export function getRegister(data){
    return request({
        url:'/front/login/registered',
        method:'post',
        data
    })
}

//获取用户信息
export function getUserInfo(data){
    return request({
        url:'/front/user/getInfo',
        method:'get',
        data
    })
}

//新增订单
export function addOrder(data){
    return request({
        url:'/front/orderMgr/add',
        method:'post',
        data
    })
}

//我的订单
export function myOrder(data){
    return request({
        url:'/front/orderMgr/myOrder',
        method:'post',
        data
    })
}

//省市下拉
export function province(data){
    return request({
        url:'/cj/areaMgr/listAll',
        method:'get',
        data
    })
}

//供应商注册
export function registeredSupplier(data){
    return request({
        url:'/front/supplierMgr/registeredSupplier',
        method:'post',
        data
    })
}

//供应商状态
export function state(data){
    return request({
        url:'/front/supplierMgr/getState',
        method:'get',
        data
    })
}

//删除订单
export function remove(data){
    return request({
        url:'/front/orderMgr/remove',
        method:'get',
        params:data
    })
}

//供应商信息
export function supplierInfo(data){
    return request({
        url:'/front/supplierMgr/getInfo',
        method:'get',
        data
    })
}

//打包
export function winrar(data){
    return request({
        url:'/front/orderMgr/downloadPackage',
        method:'get',
        params:data
    })
}


// 菜单
export function menuList(data){
    return request({
        url:'/front/menu/menuList',
        method:'post',
        params:data
    })
}
// 根据标题查询文章详情
export function getByTitle(data){
    return request({
        url:'/front/menu/getByTitle?title='+data,
        method:'get'
    })
}