import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

export default new VueRouter({
  mode: 'history',
  routes: [{
      path: '',
      name: 'index',
      redirect: '/index',
      meta: {
        keepAlive: false
      }
    },
    {
      path: "/index",
      name: 'index',
      component: () => import('../views/index'),
      meta: {
        keepAlive: false
      }
    }, {
      path: "/login",
      name: 'login',
      component: () => import('../views/login'),
      meta: {
        keepAlive: false
      }
    },
    {
      path: "/register",
      name: 'register',
      component: () => import('../views/register'),
      meta: {
        keepAlive: false
      }
    },
    {
      path: "/forgotPW",
      name: 'forgotPW',
      component: () => import('../views/forgotPW'),
      meta: {
        keepAlive: false
      }
    },
    {
      path: "/tradingPlatform",
      name: 'tradingPlatform',
      component: () => import('../views/tradingPlatform'),
      meta: {
        keepAlive: false
      }
    },
    {
      path: "/trandingDetail",
      name: 'trandingDetail',
      component: () => import('../views/trandingDetail'),
      meta: {
        keepAlive: false
      }
    },
    {
      path: "/centerIntroduced",
      name: 'centerIntroduced',
      // redirect: '/generalProfile',
      component: () => import('../views/centerIntroduced.vue'),
      meta: {
        keepAlive: true
      }

    },
    // 技术成果二级
    {

      path: "/generalProfile",
      name: 'generalProfile',
      component: () => import('../views/center/generalProfile'),
      meta: {
        keepAlive: false
      }
    },
    // 改成出长三角的列表页//废弃
    {

      path: "/organizational",
      name: 'organizational',
      component: () => import('../views/center/organizational'),
      meta: {
        keepAlive: false
      }
    },
    {

      path: "/technicalTeam",
      name: 'technicalTeam',
      component: () => import('../views/center/technicalTeam'),
      meta: {
        keepAlive: false
      }
    },
    {
      path: "/honoraryCertificate",
      name: 'honoraryCertificate',
      component: () => import('../views/center/honoraryCertificate'),
      meta: {
        keepAlive: false
      }
    },
    // 新闻资讯
    {
      path: "/news",
      name: 'news',
      component: () => import('../views/center/news.vue'),
      meta: {
        keepAlive: true
      }

    },
    // 二级技术成果不用
    {
      path: "/achievements",
      name: 'achievements',
      redirect: '/generalProfile',
      component: () => import('../views/center/achievements.vue'),
      meta: {
        keepAlive: false
      }

    },
    {
      path: "/listDetails",
      name: 'listDetails',
      component: () => import('../views/listDetails'),
      meta: {
        keepAlive: false
      }
    },
    {
      path: "/contactUs",
      name: 'contactUs',
      redirect: '/contactUsNew',
      component: () => import('../views/contactUs'),
      meta: {
        keepAlive: false
      }
    },
    // 联系我们二级
    {
      path: "/contactUsNew",
      name: 'contactUsNew',
      component: () => import('../views/contactUsNew'),
      meta: {
        keepAlive: false
      }
    },
    // 人才招聘
    {
      path: "/recruitment",
      name: 'recruitment',
      component: () => import('../views/recruitment'),
      meta: {
        keepAlive: false
      }
    },
    {
      path: "/supplier",
      name: 'supplier',
      component: () => import('../views/supplier'),
      meta: {
        keepAlive: false
      }
    }, {
      path: "/dataUpload",
      name: 'dataUpload',
      component: () => import('../views/dataUpload'),
      meta: {
        keepAlive: false
      }
    }, {
      path: "/fail",
      name: 'fail',
      component: () => import('../views/fail'),
      meta: {
        keepAlive: false
      }
    }, {
      path: "/supplierCenter",
      name: 'supplierCenter',
      component: () => import('../views/supplierCenter'),
      meta: {
        keepAlive: false
      }
    }, {
      path: "/orderEcho",
      name: 'orderEcho',
      component: () => import('../views/orderEcho'),
      meta: {
        keepAlive: false
      }
    }, {
      path: "/query",
      name: 'query',
      component: () => import('../views/h5/query'),
      meta: {
        keepAlive: false
      }
    }, {
      path: "/waybillDetails",
      name: 'waybillDetails',
      component: () => import('../views/h5/waybillDetails'),
      meta: {
        keepAlive: false
      }
    }
  ],
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})