<template>
  <div id="app" v-if="showPage==2">
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive" />
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive" />
  </div>
</template>

<script>
export default {
  data() {
    return {

      showPage: 2,
    }
  },
  methods: {
    // 添加判断方法
    _isMobile() {
      const flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      )
      // console.log(navigator.userAgent);
      return flag
    },
  },
  created() {
    if (this._isMobile()) {
      this.showPage = 1
      // alert('开始跳转手机端')
      // 正式
      window.location.href = 'http://app.rcees-csj.com/index';
      // 测试
      // window.location.href('http://www.rcees-csj.com/index','_blank')
    } 
    // console.log(this.showPage)
  },
  mounted() {
    // if (!this._isMobile) {
    //   alert("移动端");
    //   console.log(this.isMobile, this.isMobile, 'this.isMobile');
    // }
  }
}
</script>
<style lang="scss">
@import './style/animation.scss';
// Import all of Bootstrap's CSS
@import "~bootstrap/scss/bootstrap";
@import "./style/swiper-bundle.min.css";

.nav_top {
  .text {

    position: relative;
    animation-name: example;
    animation-duration: 4s;
    animation-iteration-count: 1;
    animation-direction: alternate;
  }
}

.fsb_c {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

* {
  margin: 0;
  padding: 0;
}

html,
body {
  width: 100%;
}

html,
body,
div,
ul,
li,
h1,
h2,
h3,
h4,
h5,
h6,
p,
dl,
dt,
dd,
ol,
form,
input,
textarea,
th,
td,
select {
  margin: 0;
  padding: 0;
}

* {
  box-sizing: border-box;
}

html,
body {
  min-height: 100%;
}

body {
  font-family: "Microsoft YaHei";
  font-size: .875rem;
  color: #333;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
}

ul,
ol {
  list-style: none;
}

img {
  border: none;
  vertical-align: middle;
}

a {
  text-decoration: none;
  color: #232323;
}

table {
  border-collapse: collapse;
  table-layout: fixed;
}

input,
textarea {
  outline: none;
  border: none;
}

textarea {
  resize: none;
  overflow: auto;
}

.clearfix {
  zoom: 1;
}

.clearfix:after {
  content: ".";
  width: 0;
  visibility: hidden;
  display: block;
  clear: both;
  overflow: hidden;
}

.fl {
  float: left;
}

.fr {
  float: right;
}

.tl {
  text-align: left;
}

.tc {
  text-align: center;
}

.tr {
  text-align: right;
}

.ellipse {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.inline {
  display: inline-block;
  *display: inline;
  *zoom: 1;
}
</style>
