import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import echarts from 'echarts'
import Video from 'video.js'
import 'video.js/dist/video-js.css'
import Print from './static/print'
// 动画
import 'animate.css'
import "wowjs/css/libs/animate.css"
import wow from 'wowjs'
Vue.prototype.$wow = wow

// bootstrap // Import all of Bootstrap's JS
// import * as bootstrap from 'bootstrap'



// 字体
import './assets/font/font.css'
// 滚动
import scroll from 'vue-seamless-scroll'

// import 'lib-flexible/flexible'//适配rem


import VueAMap from 'vue-amap';
import BaiduMap from 'vue-baidu-map'
Vue.use(BaiduMap, {
  // ak 是在百度地图开发者平台申请的密钥 详见 http://lbsyun.baidu.com/apiconsole/key */
  ak: 'os53Lmosw2c7TshSeFM2o2DwMtAADBMA'
})
const setHtmlFontSize = () => {
  const htmlDom = document.getElementsByTagName('html')[0];
  let htmlWidth = document.documentElement.clientWidth || document.body.clientWidth;
  if (htmlWidth >= 1980) {
    htmlWidth = 1980;
  }
  if (htmlWidth >= 1680) {
    htmlWidth = 1680;
  }

  htmlDom.style.fontSize = `${htmlWidth / 100}px`;
};
window.onresize = setHtmlFontSize;
setHtmlFontSize();

// 是否路由缓存
router.beforeEach((to, from, next) => {
 
 
  // console.log(to.path,from.path);
    
      //判断是否需要缓存
      if(to.path === '/centerIntroduced' && from.path === '/listDetails'){
        to.meta.keepAlive = true;  // 让 列表页 缓存，即不刷新
        next();
      }else {
        from.meta.keepAlive = false;  // 让 列表页 即不缓存，刷新
        next();
      }
      if(to.path === '/news' && from.path === '/listDetails'){
        to.meta.keepAlive = true;  // 让 列表页 缓存，即不刷新
        next();
      }else {
        from.meta.keepAlive = false;  // 让 列表页 即不缓存，刷新
        next();
      }
 
 
})

Vue.use(scroll)
Vue.use(VueAMap);
Vue.use(Print)
Vue.prototype.$video = Video
Vue.prototype.$echarts = echarts
Vue.config.productionTip = false
Vue.use(ElementUI);
new Vue({
  router,
  store,
  render: h => h(App),
  mounted () {
    document.dispatchEvent(new Event('render-event'))
  }
}).$mount('#app')




// 初始化vue-amap
// VueAMap.initAMapApiLoader({
//   // 高德的key
//   key: 'dbadfe6ff55882da89078419a1faf0f4',
//   // 插件集合
//   plugin: ['AMap.Autocomplete', 'AMap.PlaceSearch', 'AMap.Scale', 'AMap.OverView', 'AMap.ToolBar', 'AMap.MapType', 'AMap.PolyEditor', 'AMap.CircleEditor'],
//   // 高德 sdk 版本，默认为 1.4.4
//   v: '1.4.4'
// });