import {
     menuList, getInfo
} from '@/api/api'


const getDefaultState = () => {
    return {
        tabPosition: "left",
        newsList: [],
        leftList: [],
        allList: [],
        detailsItem: {},
        defaultName: '1',
        profileList: [],
        profileListtwo: []
    }
}

const state = getDefaultState()

const mutations = {
    setLeftList: (state, data) => {
        //   sessionStorage.setItem('userInfo', data)
        state.allList = data.Data
    }
}
const actions = {
    // menuList
    menuList({
        commit
    }) {
        return new Promise((resolve, reject) => {
            menuList().then(res => {
                commit('setLeftList', res)
                resolve(res)
            }).catch(error => {
                reject(error)
            })
        })
    },
    // get user info
    getInfo({
        commit,
        state
    }) {
        return new Promise((resolve, reject) => {
            getInfo(state.token).then(res => {
                commit('setUserInfo', res)
                resolve(res)
            }).catch(error => {
                reject(error)
            })
        })
    },

    // // user logout
    // logout({
    //     commit,
    //     state
    // }) {
    //     removeToken() // must remove  token  first
    //     resetRouter()
    //     sessionStorage.setItem('userInfo', {})
    //     commit('RESET_STATE')
    // },

    // // remove token
    // resetToken({
    //     commit
    // }) {
    //     return new Promise(resolve => {
    //         removeToken() // must remove  token  first
    //         commit('RESET_STATE')
    //         resolve()
    //     })
    // }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
